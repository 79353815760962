import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { CircularProgress } from "@material-ui/core";
import api from "../../../services/axios";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));
export default function AuthQr(props) {
  const user = JSON.parse(localStorage.user);
  const [password, setPassword] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const classes = useStyles();
  const confirm = async () => {
    if (!password) {
      return window.alert("Insira uma senha");
    }
    setLoading(true);
    api
      .post(
        "whatsapp/qr",
        {
          email: user.email,
          password,
        },
        {
          headers: {
            authorization: `Bearer ${localStorage.token}`,
          },
        },
      )
      .then(() => {
        props.close();
      })
      .catch((e) => {
        console.log(e);
        window.alert("Ocorreu um erro.");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <div>
      <Dialog
        open
        onClose={props.close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Gerar QrCode</DialogTitle>
        <DialogContent>
          {loading ? (
            <>
              <CircularProgress />
            </>
          ) : (
            <>
              <DialogContentText id="alert-dialog-description">
                Por favor, insira sua senha para gerar um QrCode.
                <form
                  className={classes.root}
                  noValidate
                  autoComplete="off"
                  onSubmit={(e) => {
                    e.preventDefault();

                    confirm().then().catch();
                  }}
                >
                  <TextField
                    id="outlined-basic"
                    label="Senha"
                    variant="outlined"
                    value={password}
                    type="password"
                    onChange={(e) => {
                      setPassword(e.currentTarget.value);
                    }}
                  />
                </form>
              </DialogContentText>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={props.close} color="primary">
            <Button variant="contained" color="secondary">
              Fechar
            </Button>
          </Button>
          <Button onClick={confirm} color="primary" autoFocus>
            <Button variant="contained" color="primary">
              Gerar QrCode
            </Button>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
