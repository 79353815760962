import socketIOClient from "socket.io-client";

let disconnectTimeout;
// const socket = socketIOClient("http://187.45.177.170:2222");
const socket = socketIOClient(process.env.REACT_APP_SOCKET_URL);

socket.on("connect", () => {
  sendPresence();
  clearTimeout(disconnectTimeout);
});

socket.on("reconnect", () => {
  sendPresence();
  clearTimeout(disconnectTimeout);
});

socket.on("is_already_logged", () => {
  window.alert("Usuário logado em outra estação!");
  window.location.replace("https://pipego.me");
});
socket.on("clinicall_updateClientInfo", (data) => {
  console.log(data, "eventoaa");
});
socket.on("error", (error) => {
  console.log(error);
});

socket.on("disconnect", (reason) => {
  disconnectTimeout = setTimeout(() => {
    window.location.reload();
  }, 15000);
});

function sendPresence() {
  clearTimeout(disconnectTimeout);
  const verify = setInterval(() => {
    if (localStorage.user) {
      const user = JSON.parse(localStorage.user);

      socket.emit("client_connected", {
        company: user.company.prefix,
        user: {
          idusers: user.idusers,
          username: user.username,
          email: user.email,
        },
      });
      clearInterval(verify);
    }
  }, 5000);
}

setInterval(() => {
  socket.emit("ping");
}, 25000);

export default socket;
